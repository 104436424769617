var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Apps } from '@mui/icons-material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useStore } from 'react-context-hook';
import { BsCardChecklist, BsEnvelopeExclamation, BsLightbulb, BsPlayCircle, FiCheckCircle, GiHamburgerMenu, } from 'react-icons/all';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import { HeaderV2 } from '@app/components/mol.header/header.component-v2';
import { axiosClient } from '@app/configure-app';
import { useStore as useS } from '@app/core/services';
import EmotionToday from '@app/modules/map/emotion';
import { getIconDynamic } from '@app/modules/map/icons.dynamic';
import { ModuleCardMap } from '@app/modules/map/module-card/module-card-map.component';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes/app-path';
import { StyledContainer } from '@atomic';
import { HomeOption, HomeOptions, MapContainer, NotificationCircle, StyledNotFound } from './map.page.style';
import { SkeletonLoadingPage } from './skeleton-loading/skeleton-loading.component';
// eslint-disable-next-line @typescript-eslint/no-var-requires
var NotificationSound = require('@assets/sounds/notification-sound.mp3');
var userUrl = 'usuario';
var GET_PERGUNTAS_COMPORT_URL = 'perguntacomportamental/existe-pergunta';
export var MapPage = function () {
    var company = useStore('company')[0];
    var userData = useS(useUserStore).userData;
    var _a = useState(false), isNotFound = _a[0], setIsNotFound = _a[1];
    var _b = useState(false), isGridMode = _b[0], setIsGridMode = _b[1];
    var _c = React.useState(0), questionnaireQuantity = _c[0], setQuestionnaireQuantity = _c[1];
    var notificationPlayer = React.useRef(undefined);
    var _d = useState(false), hasNotification = _d[0], setHasNotification = _d[1];
    var isUserAlreadyRepliedTermometroEmocionalToday = useStore('isUserAlreadyRepliedTermometroEmocionalToday')[0];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _e = useStore('afterReplyTermometroEmocionalFunction'), afterReplyTermometroEmocionalFunction = _e[0], setAfterReplyTermometroEmocionalFunction = _e[1];
    useEffect(function () {
        if (!isUserAlreadyRepliedTermometroEmocionalToday)
            setAfterReplyTermometroEmocionalFunction(function () { return true; });
        checkNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var behavioralQuestionsResponse = useQuery('behavioralQuestions', function () { return __awaiter(void 0, void 0, void 0, function () {
        var responseBehavioralQuestions, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_PERGUNTAS_COMPORT_URL)];
                case 1:
                    responseBehavioralQuestions = _b.sent();
                    return [2 /*return*/, {
                            behavioralQuestions: (_a = responseBehavioralQuestions.data) === null || _a === void 0 ? void 0 : _a.data,
                        }];
                case 2:
                    error_1 = _b.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var _f = useQuery('contar-notificacoes', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, current, err_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get('notificacao-usuario/contar?ativo=true&lido=false')];
                case 1:
                    response = _d.sent();
                    current = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : 0;
                    setHasNotification(current > 0);
                    return [2 /*return*/, (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.data];
                case 2:
                    err_1 = _d.sent();
                    console.error(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { refetchOnMount: true, retry: 1 }), contadorNotificacoes = _f.data, isLoadingContadorNotificacoes = _f.isLoading;
    var _g = useQuery('get-questionarios', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get('questionario-empresa')];
                case 1:
                    response = _b.sent();
                    /*
                    setQuestionnaireQuantity(
                      behavioralQuestionsResponse?.behavioralQuestions
                        ? response?.data?.data?.length + 1
                        : response?.data?.data?.length,
                    );
                    */
                    return [2 /*return*/, (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data];
                case 2:
                    err_2 = _b.sent();
                    console.error(err_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { refetchOnMount: true, retry: 1 }), questionarioEmpresa = _g.data, isLoadingQuestionarioEmpresa = _g.isLoading;
    var _h = useQuery('get-caminho-conhecimento', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, mappedQuantities, caminhoObject, err_3;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get('filial/trilhas')];
                case 1:
                    response = _d.sent();
                    mappedQuantities = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data.reduce(function (acc, course) { return acc + course.quantidadeCursosNaoConcluidos; }, 0);
                    caminhoObject = {
                        activeCoursesLength: (_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.length,
                        coursesNotConcludedQuantity: mappedQuantities,
                    };
                    return [2 /*return*/, caminhoObject];
                case 2:
                    err_3 = _d.sent();
                    console.error(err_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { refetchOnMount: true, retry: 1 }), caminhoDoConhecimento = _h.data, isLoadingCaminhoDoConhecimento = _h.isLoading;
    var _j = useQuery('get-communicates', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_4;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _e.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get('comunicado-empresa/quantidade-nao-lidos')];
                case 1:
                    response = _e.sent();
                    setQuestionnaireQuantity((behavioralQuestionsResponse === null || behavioralQuestionsResponse === void 0 ? void 0 : behavioralQuestionsResponse.behavioralQuestions) ? ((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.length) + 1
                        : (_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.length);
                    return [2 /*return*/, response.data.data];
                case 2:
                    err_4 = _e.sent();
                    console.error(err_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { refetchOnMount: false }), communicate = _j.data, isLoadingCommunicate = _j.isLoading;
    var _k = useQuery('get-trilhas-unidade', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, filteredActiveCourses, mappedQuantities, trilhasObject, err_5;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get('unidade-empresa/cursos/por-usuario')];
                case 1:
                    response = _b.sent();
                    filteredActiveCourses = (_a = response.data.data) === null || _a === void 0 ? void 0 : _a.filter(function (obj) {
                        return obj.cursos.some(function (course) { var _a; return (_a = course === null || course === void 0 ? void 0 : course.curso) === null || _a === void 0 ? void 0 : _a.ativo; });
                    });
                    mappedQuantities = filteredActiveCourses.reduce(function (acc, course) { return acc + course.quantidadeCursosNaoConcluidos; }, 0);
                    trilhasObject = {
                        activeCoursesLength: filteredActiveCourses === null || filteredActiveCourses === void 0 ? void 0 : filteredActiveCourses.length,
                        coursesNotConcludedQuantity: mappedQuantities,
                    };
                    return [2 /*return*/, trilhasObject];
                case 2:
                    err_5 = _b.sent();
                    console.error(err_5);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { refetchOnMount: true, retry: 1 }), trilhasUnidade = _k.data, isLoadingTrilhas = _k.isLoading;
    var modulesSystemMap = useQuery('modulo-jogador', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get('modulo-jogador')];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data.map(function (module) {
                            return {
                                order: 99,
                                name: module.nome,
                                realPath: module.link,
                                title: module.titulo,
                                description: module.descricao,
                                iconComponent: getIconDynamic(module.icone),
                            };
                        })];
                case 2:
                    err_6 = _a.sent();
                    console.error(err_6);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { refetchOnMount: true, retry: 1 }).data;
    var systemModules = [
        {
            order: 1,
            name: 'JogadorPesquisas',
            path: AppPath.Research,
            title: 'Pesquisas',
            description: 'Visualize as pesquisas lançadas pela sua empresa',
            pendingQuantity: questionnaireQuantity,
            iconComponent: React.createElement(BsCardChecklist, null),
        },
        {
            order: 2,
            name: 'JogadorCaminhosConhecimento',
            path: AppPath.GroupCourse,
            title: 'Caminho do conhecimento',
            description: 'Visualize as pílulas de aprendizado e treinamentos',
            pendingQuantity: caminhoDoConhecimento === null || caminhoDoConhecimento === void 0 ? void 0 : caminhoDoConhecimento.coursesNotConcludedQuantity,
            iconComponent: React.createElement(BsLightbulb, null),
        },
        {
            order: 3,
            name: 'JogadorComunicados',
            path: AppPath.Communicate,
            title: 'Comunicados',
            description: 'Visualize comunicados importantes',
            pendingQuantity: communicate,
            iconComponent: React.createElement(BsEnvelopeExclamation, null),
        },
        {
            order: 4,
            name: 'JogadorChecklist',
            path: AppPath.RoutineChecklist,
            title: 'Checklist',
            description: 'Visualize os checklist da sua unidade',
            iconComponent: React.createElement(FiCheckCircle, null),
        },
        {
            order: 99,
            name: 'JogadorTutorial',
            path: AppPath.Tutorial,
            title: 'Tutorial',
            description: 'Aprenda como o jogo funciona.',
            pendingQuantity: 0,
            iconComponent: React.createElement(BsPlayCircle, null),
            unrestricted: true,
        },
    ];
    var buildModuleCard = function (module) {
        return (React.createElement(ModuleCardMap, { key: module.name, gridMode: isGridMode, path: module.path, realPath: module.realPath, title: module.title, description: module.description, pendingQuantity: module.pendingQuantity, iconComponent: module.iconComponent }));
    };
    useEffect(function () {
        checkNotifications();
    }, []);
    var playAudio = function () {
        notificationPlayer &&
            notificationPlayer.current &&
            notificationPlayer.current.play().catch(function (error) {
                console.error('Error attempting to PLAY', error);
            });
    };
    var checkNotifications = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, current, previous;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    notificationPlayer.current.click();
                    return [4 /*yield*/, axiosClient.get('notificacao-usuario/contar?ativo=true&lido=false')];
                case 1:
                    resp = _c.sent();
                    current = (_b = (_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : 0;
                    previous = localStorage.getItem('currentNotificationsCount');
                    setHasNotification(current > 0);
                    if (!previous || previous < current) {
                        localStorage.removeItem('currentNotificationsCount');
                        localStorage.setItem('currentNotificationsCount', current);
                        notificationPlayer.current.click();
                        playAudio();
                    }
                    setInterval(function () {
                        axiosClient.get('notificacao-usuario/contar?ativo=true&lido=false').then(function (response) {
                            var _a, _b;
                            var currentNotificationsCount = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : 0;
                            setHasNotification(currentNotificationsCount > 0);
                            var previousNotificationCount = localStorage.getItem('currentNotificationsCount');
                            if (!previousNotificationCount || previousNotificationCount < currentNotificationsCount) {
                                localStorage.removeItem('currentNotificationsCount');
                                localStorage.setItem('currentNotificationsCount', currentNotificationsCount);
                                notificationPlayer.current.click();
                                playAudio();
                            }
                        });
                    }, 60000);
                    return [2 /*return*/];
            }
        });
    }); };
    function RolesMenu(_a) {
        var _b;
        var rolesToMap = _a.rolesToMap;
        if (rolesToMap === undefined)
            return React.createElement(React.Fragment, null);
        var modules = systemModules.concat(modulesSystemMap !== null && modulesSystemMap !== void 0 ? modulesSystemMap : []);
        if (typeof rolesToMap === 'string') {
            var module = (_b = modules.find(function (mapping) { return mapping.name === rolesToMap; })) !== null && _b !== void 0 ? _b : null;
            return module ? buildModuleCard(module) : null;
        }
        var rolesModules = modules
            .filter(function (module) { return rolesToMap.includes(module.name); })
            .sort(function (a, b) { return (a.order || 0) - (b.order || 0); });
        // add modules unrestricted
        var unrestrictedModules = modules.filter(function (module) { return module.unrestricted; });
        var mapComponents = new Map();
        rolesModules.forEach(function (module) { return mapComponents.set(module.name, module); });
        unrestrictedModules.forEach(function (module) { return mapComponents.set(module.name, module); });
        var components = Array.from(mapComponents.values());
        return (React.createElement(React.Fragment, null, components.map(function (module) {
            return buildModuleCard(module);
        })));
    }
    return (React.createElement(React.Fragment, null, isNotFound ? (React.createElement(NotFound, null)) : (React.createElement(StyledContainer, null,
        React.createElement(EmotionToday, null),
        React.createElement(HomeOptions, null,
            React.createElement(HomeOption, { style: { position: 'relative' } },
                hasNotification && React.createElement(NotificationCircle, null),
                React.createElement(NavLink, { to: "/notifications", exact: true },
                    React.createElement(NotificationsNoneIcon, { sx: { fill: '#fff', fontSize: '1.8rem' } }))),
            React.createElement(HomeOption, null,
                React.createElement(NavLink, { to: "/player-profile", exact: true },
                    React.createElement(PersonOutlineOutlinedIcon, { sx: { fill: '#fff', fontSize: '1.8rem' } }))),
            React.createElement(HomeOption, { onClick: function () {
                    setIsGridMode(!isGridMode);
                } }, isGridMode ? (React.createElement(GiHamburgerMenu, { style: { color: 'white', fontSize: '23px' } })) : (React.createElement(Apps, { sx: { fill: '#fff', fontSize: '1.4rem' } })))),
        React.createElement("audio", { ref: notificationPlayer, src: NotificationSound }),
        React.createElement(HeaderV2, { withBackButton: false, companyLogoURL: company === null || company === void 0 ? void 0 : company.imgLogoEscuro }),
        isLoadingTrilhas ||
            isLoadingCommunicate ||
            isLoadingCaminhoDoConhecimento ||
            isLoadingQuestionarioEmpresa ? (React.createElement(SkeletonLoadingPage, null)) : (React.createElement(MapContainer, { gridMode: isGridMode },
            React.createElement(RolesMenu, { rolesToMap: userData === null || userData === void 0 ? void 0 : userData.roles })))))));
};
var NotFound = function () {
    return (React.createElement(StyledNotFound, null,
        React.createElement("h1", null, "Tivemos um erro inesperado!"),
        React.createElement("h2", null, "Contate o nosso suporte.")));
};
