var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { BsCamera, BsCheckCircle, BsJournalText, BsPlus, BsQuestionCircle, BsXCircle } from 'react-icons/all';
import { useMutation } from 'react-query';
import ImagePreview from '@app/components/image-preview/image-preview';
import { axiosClient } from '@app/configure-app';
import { CloseIconStyled } from '@app/modules/track/modal/modal.component.style';
import { ActivityIndicator, Button, FaIcon } from '@atomic';
import { FlexColumn } from '../../quick-checklist/styles';
import { ITEM_STATE } from '../checklist/checklist.component';
import { ActionPlan, ActionPlanSubmittedInfo, ActionsWrapper, EnviarFoto, EnviarFotoSubmittedInfo, EnviarObservacao, EnviarObservacaoSubmittedInfo, ItemButtonsWrapper, Question, } from './checklist-item.styled';
var styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};
export var ChecklistItem = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var checklistItem = _a.checklistItem, isDisabled = _a.isDisabled, handleOnClick = _a.handleOnClick, itemState = _a.itemState, handleOnActionPlanClick = _a.handleOnActionPlanClick, handleOnEnviarFotoClick = _a.handleOnEnviarFotoClick, handleOnObservacaoClick = _a.handleOnObservacaoClick, isObservacaoEnviada = _a.isObservacaoEnviada, isActionPlanRegistered = _a.isActionPlanRegistered, isFotoEnviada = _a.isFotoEnviada, _h = _a.actionPlanSent, actionPlanSent = _h === void 0 ? false : _h, itemLoading = _a.itemLoading, fotoPreview = _a.fotoPreview, removeFotoPreview = _a.removeFotoPreview, boxBackgroundColor = _a.boxBackgroundColor;
    var _j = useState((checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) !== null && (checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) !== undefined), showForms = _j[0], setShowForms = _j[1];
    var addOrReplaceRespostaGestor = function (isEfetivo) {
        setShowForms(true);
        handleOnClick(checklistItem.id, isEfetivo);
    };
    var _k = useState(false), mostrarModalConformidade = _k[0], setmostrarModalConformidade = _k[1];
    var conformidadeHandler = function () {
        setmostrarModalConformidade(true);
    };
    var fecharModalConformidadeHandler = function () {
        setmostrarModalConformidade(false);
    };
    useEffect(function () {
        setShowForms((checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) !== null && (checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) !== undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checklistItem]);
    useEffect(function () {
        setShowForms((checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) !== null && (checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) !== undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemLoading]);
    var removerFoto = function () {
        console.log('remover foto placeholder');
        // no-op
        // mutate();
    };
    var _l = useMutation(function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    console.log('checklistItem?.resposta id', checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.resposta);
                    return [4 /*yield*/, axiosClient.post("checklistunidade/item/resposta/" + (((_a = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.resposta) === null || _a === void 0 ? void 0 : _a.id) || ((_b = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _b === void 0 ? void 0 : _b.id)) + "/upload-foto", { ArquivoFotoBase64: '' })];
                case 1:
                    response = _c.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function (data) {
            if (data.succeeded) {
                removeFotoPreview(data.data.checklistUnidadeItemId);
            }
        },
        onError: function (data) {
            console.log('erro', data);
        },
    }), mutate = _l.mutate, isLoading = _l.isLoading;
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: mostrarModalConformidade, onClose: fecharModalConformidadeHandler, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement(Box, { sx: styleModal },
                React.createElement("div", { style: { display: 'flex', justifyContent: 'flex-end' } },
                    React.createElement(CloseIconStyled, { onClick: fecharModalConformidadeHandler })),
                React.createElement(Typography, { id: "modal-modal-title", variant: "h6", component: "h2", style: { display: 'flex', justifyContent: 'center' } }, "Informa\u00E7\u00F5es Adicionais"),
                React.createElement(Typography, { id: "modal-modal-description", sx: { mt: 2 } }, checklistItem.conformidade),
                React.createElement("img", { src: checklistItem.imagemConformidade }),
                React.createElement(Button, { onClick: fecharModalConformidadeHandler }, "Ok"))),
        React.createElement("div", { className: "isRequired", style: {
                borderTop: '1px solid rgba(73, 68, 60, 0.7)',
                borderBottom: '1px solid rgba(73, 68, 60, 0.7)',
                background: boxBackgroundColor !== null && boxBackgroundColor !== void 0 ? boxBackgroundColor : 'none',
                marginBottom: '5px',
            } },
            React.createElement("div", { style: { paddingInline: '16px' } }, !itemLoading ? (React.createElement(React.Fragment, null,
                React.createElement(Question, null,
                    checklistItem.titulo,
                    React.createElement("button", { onClick: conformidadeHandler, style: {
                            background: 'none',
                            border: 'none',
                            paddingLeft: '5px',
                        } },
                        React.createElement(BsQuestionCircle, { fontSize: 20 }))),
                React.createElement(ItemButtonsWrapper, null,
                    React.createElement(Button, { disabled: isDisabled, variant: "neutral", style: {
                            backgroundColor: itemState == ITEM_STATE.WRONG ? '#C70000' : '#ffffff',
                            border: '2px solid #C70000',
                            borderRadius: '8px',
                            width: '100%',
                            minHeight: '37px',
                            textAlign: 'center',
                        }, onClick: function () { return addOrReplaceRespostaGestor(false); } },
                        React.createElement(BsXCircle, { fontSize: 24, style: {
                                color: itemState == ITEM_STATE.WRONG ? '#ffffff' : '#C70000',
                            } })),
                    (checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.permiteRespostaNA) ? (React.createElement(Button, { disabled: isDisabled, variant: "neutral", style: {
                            backgroundColor: itemState == ITEM_STATE.NULL ? '#007DF0' : '#ffffff',
                            width: '100%',
                            border: '2px solid #007DF0',
                            borderRadius: '8px',
                            minHeight: '37px',
                            textAlign: 'center',
                            color: '#007DF0',
                        }, onClick: function () { return addOrReplaceRespostaGestor(null); } },
                        React.createElement("span", { style: {
                                fontSize: '20px',
                                color: itemState == ITEM_STATE.NULL ? '#ffffff' : '#007DF0',
                            } }, "N/A"))) : null,
                    React.createElement(Button, { disabled: isDisabled, variant: "neutral", style: {
                            backgroundColor: itemState == ITEM_STATE.RIGHT ? '#008425' : '#ffffff',
                            width: '100%',
                            border: '2px solid #008425',
                            borderRadius: '8px',
                            minHeight: '37px',
                            textAlign: 'center',
                            color: '#008425',
                        }, onClick: function () { return addOrReplaceRespostaGestor(true); } },
                        React.createElement(BsCheckCircle, { fontSize: 24, style: {
                                color: itemState == ITEM_STATE.RIGHT ? '#ffffff' : '#008425',
                            } }))),
                (showForms || itemState != ITEM_STATE.NOT_ANSWERED) && (React.createElement(React.Fragment, null,
                    React.createElement(ActionsWrapper, null,
                        React.createElement(EnviarFoto, { onClick: handleOnEnviarFotoClick },
                            React.createElement(EnviarFotoSubmittedInfo, null,
                                isFotoEnviada || ((_c = (_b = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _b === void 0 ? void 0 : _b.urlFotoRespostaList) === null || _c === void 0 ? void 0 : _c.length) > 0 ? (React.createElement(FaIcon.Check, { style: { color: '#008425' } })) : (React.createElement(BsCamera, { style: { fontSize: '20px', color: '#49443C' } })),
                                React.createElement("span", { style: { color: '#49443C', fontWeight: 'bold' } },
                                    "Imagem ",
                                    checklistItem.fotoObrigatoria ? '*' : ''))),
                        React.createElement(EnviarObservacao, { onClick: handleOnObservacaoClick },
                            React.createElement(EnviarObservacaoSubmittedInfo, null,
                                isObservacaoEnviada || ((_d = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _d === void 0 ? void 0 : _d.observacao) ? (React.createElement(FaIcon.Check, { style: { color: '#008425' } })) : (React.createElement(BsJournalText, { style: { fontSize: '20px', color: '#49443C' } })),
                                React.createElement("span", { style: { color: '#49443C', fontWeight: 'bold' } }, "Observa\u00E7\u00E3o")))),
                    React.createElement(ActionsWrapper, null, itemState == ITEM_STATE.WRONG && (React.createElement(ActionPlan, { onClick: handleOnActionPlanClick },
                        React.createElement(ActionPlanSubmittedInfo, { style: {
                                borderColor: isActionPlanRegistered || ((_e = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _e === void 0 ? void 0 : _e.planoAcaoChecklistUnidade) ||
                                    actionPlanSent
                                    ? '#008425'
                                    : '#49443C',
                            } },
                            isActionPlanRegistered || ((_f = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _f === void 0 ? void 0 : _f.planoAcaoChecklistUnidade) ||
                                actionPlanSent ? (React.createElement(FaIcon.Check, { style: { color: '#008425' } })) : (React.createElement(BsPlus, { style: { fontSize: '25px', color: '#49443C' } })),
                            React.createElement("span", { style: {
                                    color: isActionPlanRegistered || ((_g = checklistItem === null || checklistItem === void 0 ? void 0 : checklistItem.respostas[0]) === null || _g === void 0 ? void 0 : _g.planoAcaoChecklistUnidade) ||
                                        actionPlanSent
                                        ? '#008425'
                                        : '#49443C',
                                    fontWeight: 'bold',
                                } }, "Plano de A\u00E7\u00E3o"))))))), fotoPreview === null || fotoPreview === void 0 ? void 0 :
                fotoPreview.fotoUrlList.map(function (url, idx) { return (React.createElement(ImagePreview, { key: idx, imageUrl: url })); }))) : (React.createElement(FlexColumn, { style: { paddingBlock: '3rem' } },
                React.createElement(ActivityIndicator, { type: "spinner", size: "2x" })))))));
};
